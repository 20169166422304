<script setup>
import { computed, ref } from "vue";
import Login from "@/components/General/Auth/Components/Login.vue";
import PasswordReset from "@/components/General/Auth/Components/PasswordReset.vue";
import { useRoute } from "vue-router/composables";
import PasswordForgotten from "@/components/General/Auth/Components/PasswordForgotten.vue";

const route = useRoute();
const mode = ref("login");
const backgroundStyle = computed(() => {
  /* Add code for changing backgrounds etc. here */
  let url =
    "https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80";
  return `background: url(${url}) no-repeat center/cover`;
});
</script>

<template>
  <div class="row h-100">
    <div
      class="background-image col-lg-4 col-md-5 d-none d-md-block bg-primary"
      :style="backgroundStyle"
    />
    <div
      class="forms col-12 col-md-7 col-lg-8 d-flex align-items-center justify-content-center p-7"
    >
      <Login
        v-if="route.name === 'login' && mode === 'login'"
        @password-reset="mode = 'passwordForgot'"
      />
      <PasswordForgotten
        v-else-if="route.name === 'login' && mode === 'passwordForgot'"
        @login="mode = 'login'"
      />
      <PasswordReset
        v-else-if="route.name === 'passwordReset'"
        @login="mode = 'login'"
      />
    </div>
  </div>
</template>
